import React from 'react';
import Container from 'src/components/Container';
import Pagination from 'components/Pagination';
import Seo from 'src/lib/components/Seo';
import PageHeader from 'src/components/PageHeader';
import LatestArticlesGrid from 'src/components/LatestArticlesGrid/Component';
import Block from 'src/components/Block';
import Breadcrumbs, { Props as BreadcrumbsProps } from 'src/components/Breadcrumbs';
import Spacer from 'src/components/Spacer';
import { resolveUrl } from 'helpers';
import ContentParser from 'src/components/ContentParser';
import { PostlikeWithImageData } from 'src/types';
import { SitePage } from 'src/models/SitePage';
import { Post } from 'src/models/Post';
import TileGrid from 'components/TileGrid';
import GeneralTile from 'components/tiles/GeneralTile';
import useThumbnailPlaceholder from 'src/hooks/queries/fileSystemAssets/useThumbnailPlaceholder';

export interface Props {
  relatedPage: SitePage | null,
  gridItems: PostlikeWithImageData[] | Post[],
  selectedCategory?: null | {
    name: string,
    slug: string,
  },
  pageContext?: {
    currentPage: number,
    limit: number,
    numPages: number,
    skip: number,
    categorySlugsWhitelist: string[],
    activeCategorySlug?: string,
  },
}


function isPostArrayGuard(input: any[]): input is Post[] {
  return input.every((item) => item instanceof Post);
}


const GridPagePart:React.FC<Props> = ({
  relatedPage,
  gridItems,
  pageContext,
  selectedCategory,
}) => {
  if (!relatedPage) throw new Error('Missing relatedPage');
  const thumbnailPlaceholder = useThumbnailPlaceholder();
  const defaultPageContent: Required<Props['pageContext']> = {
    currentPage: 1,
    limit: 1,
    numPages: 1,
    skip: 0,
    categorySlugsWhitelist: [],
    activeCategorySlug: '',
  };
  const { currentPage, numPages } = pageContext || defaultPageContent;
  const pageNumberString = currentPage > 1
    ? `page ${currentPage}`
    : '';

  const categoryString = selectedCategory
    ? `${selectedCategory.name || ''} posts`
    : '';

  const { metaDescription, h1 } = relatedPage;
  const pageTitleWithCategory = categoryString
    ? `${h1}: ${categoryString}`
    : h1;
  const pageTitleWithNumbering = pageNumberString
    ? `${pageTitleWithCategory} - ${pageNumberString}`
    : pageTitleWithCategory;

  let breadcrumbs: BreadcrumbsProps['items'];
  if (selectedCategory) {
    breadcrumbs = [{
      label: relatedPage.navTitle,
      url: resolveUrl.post(''),
    }, {
      label: categoryString,
    }];
  } else {
    breadcrumbs = [{ label: relatedPage.navTitle }];
  }

  return (
    <>
      <Seo
        title={pageTitleWithNumbering}
        description={`${metaDescription} ${pageNumberString}`}
      />
      <PageHeader
        h1={pageTitleWithCategory}
        text={pageNumberString}
        backgroundText={relatedPage.contentHtml}
      />
      <Container>
        <Breadcrumbs items={breadcrumbs} />
        <Block>
          {relatedPage.contentHtml && (
            <Container fluid yPadding={0} xPadding={0} readableWidth>
              <ContentParser>{relatedPage.contentHtml}</ContentParser>
              <Spacer mb={5} />
            </Container>
          )}

          {isPostArrayGuard(gridItems) ? (
            <LatestArticlesGrid data={gridItems} />
          ) : (
            <TileGrid>
              {gridItems.map((item, i) => (
                <GeneralTile
                  key={item.title + i}
                  href={item.slug}
                  image={(
                    item.imageData
                    || thumbnailPlaceholder?.childImageSharp?.gatsbyImageData
                  )}
                  title={item.title}
                />
              ))}
            </TileGrid>
          )}

          {numPages > 1 && (
            <Pagination
              currentPage={currentPage}
              numberOfPages={numPages}
              hrefPattern={(itemIndex) => {
                const isFirstPage = !itemIndex;
                const baseUrl = selectedCategory
                  ? resolveUrl.category(selectedCategory.slug || '', resolveUrl.post(''))
                  : resolveUrl.post('');
                if (isFirstPage) return baseUrl;
                else return `${baseUrl}${itemIndex + 1}/`;
              }}
            />
          )}
        </Block>
      </Container>
    </>
  );
};
export default GridPagePart;
