import React from 'react';
import Button from 'components/Button';

interface Props {
  label: string | number,
  href: string,
  active?: boolean,
}

const PaginationButton:React.FC<Props> = ({
  label,
  href,
  active,
}) => {
  return (
    <Button
      href={href}
      circle
      type={active ? 'primary' : 'clear'}
    >
      {label}
    </Button>
  );
};
export default PaginationButton;
