type NumberNullArray = Array<number | null>;

const cleanArray = (array: NumberNullArray): NumberNullArray => {
  const usedIndexes: number[] = [];
  const cleanItems: NumberNullArray = [];
  // allow multiple nulls but prevent dup numbers
  array.forEach((item) => {
    if (item === null) cleanItems.push(item);
    else if (!usedIndexes.includes(item)) {
      usedIndexes.push(item);
      cleanItems.push(item);
    }
  });
  // no leading or trailing null
  while (cleanItems.length && cleanItems[cleanItems.length - 1] === null) cleanItems.pop();
  while (cleanItems.length && cleanItems[0] === null) cleanItems.shift();
  return cleanItems;
};

export const getIndexes = (currentIndex: number, numberOfPages: number): NumberNullArray => {
  const allIndexes = Array.from(Array(numberOfPages).keys());
  if (numberOfPages <= 5) return allIndexes;
  if (currentIndex <= 3) {
    return cleanArray([
      ...allIndexes.slice(0, 5),
      null,
      numberOfPages - 1,
    ]);
  }
  if (currentIndex >= numberOfPages - 5) {
    return cleanArray([
      0,
      null,
      ...allIndexes.slice(currentIndex - 1, numberOfPages),
    ]);
  }
  return cleanArray([
    0,
    null,
    currentIndex - 1,
    currentIndex,
    currentIndex + 1,
    null,
    numberOfPages - 1,
  ]);
};
