import React from 'react';
import { graphql } from 'gatsby';
import GridPagePart, { Props as GridPagePartProps } from 'src/components/_templateParts/GridPagePart';
import usePostsPage from 'src/hooks/queries/usePostsPage';
import PostQuery_Post from 'src/transformers/PostQuery_Post';
import isNotNull from 'src/types/guards/isNotNull';

interface Props {
  data: GatsbyTypes.BlogListQuery,
  pageContext: {
    currentPage: number,
    limit: number,
    numPages: number,
    skip: number,
    categorySlugsWhitelist: string[],
    activeCategorySlug?: string,
  },
}

export const query = graphql`
  query BlogList(
    $skip: Int!,
    $limit: Int!,
    $categorySlugsWhitelist: [String]!,
    $activeCategorySlug: String,
  ) {
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { in: $categorySlugsWhitelist } } } } }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...PostWithThumbnail
      }
    }
    selectedCategory: allWpCategory(filter: { slug: { eq: $activeCategorySlug } }) {
      nodes {
        name
        slug
      }
    }
  }
`;

const BlogPage: React.FC<Props> = ({ data, pageContext }) => {
  const postsRaw = data.allWpPost.nodes;
  const categoryName = data?.selectedCategory?.nodes[0]?.name || '';
  const categorySlug = data?.selectedCategory?.nodes[0]?.slug || '';
  const selectedCategory: GridPagePartProps['selectedCategory'] = (
    categoryName && categorySlug && {
      name: categoryName,
      slug: categorySlug,
    }) || null;
  const posts = Array.from(postsRaw, PostQuery_Post).filter(isNotNull);
  const postsPage = usePostsPage();

  return (
    <GridPagePart
      relatedPage={postsPage}
      gridItems={posts}
      selectedCategory={selectedCategory}
      pageContext={pageContext}
    />
  );
};

export default BlogPage;
