import React from 'react';
// import styled from 'styled-components';
import TileGrid from 'components/TileGrid';
import BlogTile from 'components/tiles/BlogTile';
import { Post } from 'src/models/Post';
import { resolveUrl } from 'helpers';

interface Props {
  data: Post[],
}

const LatestArticlesGrid:React.FC<Props> = ({ data }) => {
  const tiles = data.map((post) => (
    <BlogTile
      key={post._id}
      title={post.title}
      date={post.date}
      href={resolveUrl.post(post.slug, true, '')}
      image={post.featuredImage}
      taxonomyLinks={post.taxonomyLinks}
      // todo - uncategorized filtering?
    />
  ));

  return <TileGrid>{tiles}</TileGrid>;
};
export default LatestArticlesGrid;

// const LatestArticlesGridOuter = styled.div``;
