import { useStaticQuery, graphql } from 'gatsby';

import { SitePage } from 'models/SitePage';

export default (): SitePage => {
  const result = useStaticQuery<GatsbyTypes.PostsPageQuery>(graphql`
    query PostsPage {
      wpPage(isPostsPage: {eq: true}) {
        ...BasicPageBasic
      }
    }
  `);
  return new SitePage({
    slug: result.wpPage?.slug || '',
    contentHtml: result.wpPage?.content || '',
    metaDescription: result.wpPage?.acfSeo?.metaDescription || '',
    title: result.wpPage?.title || '',
    metaTitle: result.wpPage?.acfSeo?.metaTitle,
    navTitle: result.wpPage?.acfSeo?.navTitle,
  });
};
