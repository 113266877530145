import React from 'react';
import styled from 'styled-components';
import GeneralTile from 'components/tiles/GeneralTile';
import { Props as GeneralTileProps } from '../GeneralTile';

export interface Props extends GeneralTileProps {
  title: NonNullable<GeneralTileProps['title']>,
  date: NonNullable<GeneralTileProps['date']>,
  subtitle?: never,
}

const BlogTile:React.FC<Props> = (props) => {
  return (
    <BlogTileOuter>
      <GeneralTile
        {...props}
      />
    </BlogTileOuter>
  );
};
export default BlogTile;

const BlogTileOuter = styled.div``;
