import React from 'react';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import { BreakpointsBasic } from 'styled';

export interface Props {
  children: React.ReactNode[],
  gutter?: number,
  tileMarginBottom?: React.CSSProperties['marginBottom'],
  breakpoint?: keyof BreakpointsBasic,
  itemsPerRow?: number,
}

const TileGrid:React.FC<Props> = ({
  children,
  gutter = 10,
  tileMarginBottom = '30px',
  breakpoint = 'tablet',
  itemsPerRow = 3,
}) => {
  const ids = children.map((tile) => ({
    id: nanoid(),
    tile,
  }));
  return (
    <TileGridOuter
      gutter={gutter}
      breakpoint={breakpoint}
    >
      {ids.map(({ tile, id }) => (
        <ItemWrap
          key={id}
          gutter={gutter}
          tileMarginBottom={tileMarginBottom}
          itemsPerRow={itemsPerRow}
        >
          {tile}
        </ItemWrap>
      ))}
    </TileGridOuter>
  );
};
export default TileGrid;

interface SharedProps {
  gutter: number,
}
const TileGridOuter = styled.div<SharedProps & { breakpoint: NonNullable<Props['breakpoint']> }>`
  ${({ theme, breakpoint }) => theme.breakpoints.up(breakpoint)} {
    display: flex;
    flex-wrap: wrap;
    margin: 0 ${(p) => p.gutter * -1}px;
  }
`;
interface ItemWrapProps {
  itemsPerRow: number,
  tileMarginBottom: Props['tileMarginBottom'],
}
const ItemWrap = styled.div<SharedProps & ItemWrapProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 0 0 ${(p) => 100 / p.itemsPerRow}%;
  padding: ${(p) => p.gutter}px;
  margin-bottom: ${(p) => p.tileMarginBottom};
`;
