import { nanoid } from 'nanoid';
import React from 'react';
import styled from 'styled-components';
import PaginationButton from './PaginationButton';
import { getIndexes } from './services';

export interface Props {
  numberOfPages: number,
  currentPage: number,
  hrefPattern: string | ((itemIndex: number, currentIndex: number) => string),
  noTrailingSlash?: boolean,
}

const Main:React.FC<Props> = ({
  numberOfPages,
  currentPage,
  hrefPattern,
  noTrailingSlash = false,
}) => {
  const conditionalTrailingSlash = noTrailingSlash ? '' : '/';

  const currentIndex = currentPage - 1;

  const indexOrNulls = getIndexes(currentIndex, numberOfPages);
  const indexOrNullsWithIds = indexOrNulls.map((index) => ({
    item: index,
    id: nanoid(),
  }));

  const getHref = (itemIndex: number): string => {
    if (typeof hrefPattern === 'function') return hrefPattern(itemIndex, currentIndex);
    return `${hrefPattern}${itemIndex + 1}${conditionalTrailingSlash}`;
  };

  return (
    <MainOuter>
      {indexOrNullsWithIds.map(({ item, id }) => (
        <ButtonWrap key={id}>
          {item === null ? <Ellipsis /> : (
            <PaginationButton
              href={getHref(item)}
              label={item + 1}
              active={item + 1 === currentPage}
            />
          )}
        </ButtonWrap>
      ))}
    </MainOuter>
  );
};
export default Main;

const MainOuter = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
`;
const Ellipsis = styled.div.attrs(() => ({ children: '...' }))`
  transform: translateY(-0.25em);
  font-weight: bold;
`;
